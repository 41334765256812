import * as disqualify from '../products/quickterm-disqualify';
import * as productQuestions from '../products/quickterm-questions';
import * as settings from '../products/quickterm-settings';
import {
    isEmail,
    isPhoneNumber,
} from './contact';

export const extractQuestions = (bundle, uiType) => {

    let newQuestions = [];

    switch (uiType) {

        case "carousel":
            for (let question of bundle.questions) {
                if ("radio" === question.type) {
                    let newQuestion = {};
                    newQuestion.id = question.id;
                    newQuestion.copy = question.copy;
                    newQuestion.help = question.help;
                    for (let [choiceId, choice] of Object.entries(question.choices)) {
                        switch (choice.copy) {
                            case "No":
                                newQuestion["no"] = choiceId;
                                break;
                            case "Yes":
                                newQuestion["yes"] = choiceId;
                                break;
                            default:
                                break;
                        }
                    }
                    if (undefined !== question.bullets && question.bullets.length > 0) {
                        newQuestion.bullets = question.bullets;
                    }
                    newQuestions.push(newQuestion);
                }

            }

            break;
        case "sorted":
            for (let question of bundle.questions) {
                if ("radio" === question.type) {
                    let newQuestion = {};
                    newQuestion.id = question.id;
                    newQuestion.copy = question.copy;
                    newQuestion.order = question.order;
                    newQuestion.help = question.help;
                    newQuestion.choices = new Map();
                    let unsortedChoices = [];
                    for (let [choiceId, choice] of Object.entries(question.choices)) {

                        unsortedChoices.push(choice);

                        switch (choice.copy) {
                            case "No":
                                newQuestion["no"] = choiceId;
                                break;
                            case "Yes":
                                newQuestion["yes"] = choiceId;
                                break;
                            default:
                                break;
                        }
                    }
                    if (undefined !== question.bullets && question.bullets.length > 0) {
                        newQuestion.bullets = question.bullets;
                    }

                    // we sort the choices by id
                    unsortedChoices.sort((a, b) => a.order - b.order);
                    for (let item of unsortedChoices) {
                        newQuestion.choices.set(item.id, item);
                    }

                    newQuestions.push(newQuestion);
                }
            }
            newQuestions.sort((a, b) => a.order - b.order);

            break;
        case "toggle":
            for (let question of bundle.questions) {
                if ("radio" === question.type) {
                    let newQuestion = {};
                    newQuestion.id = question.id;
                    newQuestion.copy = question.copy;
                    newQuestion.help = question.help;
                    for (let [choiceId, choice] of Object.entries(question.choices)) {
                        switch (choice.copy) {
                            case "No":
                                newQuestion["no"] = choiceId;
                                break;
                            case "Yes":
                                newQuestion["yes"] = choiceId;
                                break;
                            default:
                                break;
                        }
                    }
                    if (undefined !== question.drilldown && question.drilldown.length > 0) {
                        newQuestion.drilldown = question.drilldown;
                    }
                    if (undefined !== question.followUp && question.followUp.length > 0) {
                        newQuestion.followUp = question.followUp;
                    }
                    newQuestions.push(newQuestion);
                }
            }
            break;
        default:
            break;

    }

    return newQuestions;

}

export const prepareGeoFields = ({ geoData }) => {

    if (undefined === geoData) {
        return {};
    } else {
        return {
            city: geoData.city,
            geo_city: geoData.city,
            province: geoData.province,
            province_current: geoData.province_code,
            geo_province: geoData.province,
            country: geoData.country,
            geo_country: geoData.country
        };
    }

}

export const getHappyPath = ({ brokerConfig, product }) => {

    let happyPath;

    //todo extract from brokerConfig
    switch (product) {
        case "quickterm":
        case "debug":
            // happyPath = {
            //     welcome: 'quoter',
            //     quoter: 'general',
            //     general: 'introduction',
            //     introduction: 'diagnosis',
            //     diagnosis: 'last10',
            //     last10: 'last5',
            //     last5: 'last2',
            //     last2: 'medical',
            //     medical: 'occupation',
            //     occupation: 'misc',
            //     misc: 'address',
            //     address: 'beneficiary',
            //     beneficiary: 'payment',
            //     payment: 'consent',
            //     consent: 'policy'
            // };
            happyPath = {
                quote: {
                    name: 'Quote',
                    pages: {
                        welcome: 'quoter',
                        quoter: 'next'
                    },
                    next: 'personalinfo'
                },
                personalinfo: {
                    name: "Personal Info",
                    pages: {
                        general: 'introduction',
                        introduction: 'next'
                    },
                    next: 'medicalinfo'
                },
                medicalinfo: {
                    name: "Medical",
                    pages: {
                        diagnosis: 'last10',
                        last10: 'last5',
                        last5: 'last2',
                        last2: 'medical',
                        medical: 'occupation',
                        occupation: 'misc',
                        misc: 'next',
                    },
                    next: 'address'
                },
                address: {
                    name: 'Address',
                    pages: {
                        address: 'next',
                    },
                    next: 'beneficiary'
                },
                beneficiary: {
                    name: 'Beneficiary',
                    pages: {
                        beneficiary: 'next',
                    },
                    next: 'payment'
                },
                payment: {
                    name: 'Payment',
                    pages: {
                        payment: 'consent',
                        consent: 'policy'
                    },
                    next: undefined
                },
            };
            break;
        default:
            // happyPath = {
            //     welcome: 'quoter',
            //     quoter: 'general',
            //     general: 'introduction',
            //     introduction: 'diagnosis',
            //     diagnosis: 'end',
            //     last10: 'last5',
            //     last5: 'last2',
            //     last2: 'medical',
            //     medical: 'occupation',
            //     occupation: 'misc',
            //     misc: 'end',
            //     address: 'beneficiary',
            //     beneficiary: 'payment',
            //     payment: 'consent',
            //     consent: 'policy'
            // };
            break;
    }

    return happyPath;

}

export const attachHappyPath = ({ happyPath }) => {
    let screenSequence = [];
    let currentSection = 'quote';
    let nextScreen = "welcome";
    while (currentSection) {
        while (nextScreen !== 'next') {
            screenSequence.push(nextScreen);
            nextScreen = happyPath[currentSection].pages[nextScreen];
            if (nextScreen === undefined) {
                break;
            }
        }
        currentSection = happyPath[currentSection].next;
        if (currentSection) {
            nextScreen = Object.keys(happyPath[currentSection].pages)[0];
        } else {
            break;
        }
    }


    return {
        happyPath: { ...happyPath },
        screenSequence: screenSequence
    }
}

export const getNextLayout = (layout) => {
    let currentSection = layout.currentSection;
    let currentScreen = layout.currentScreen;
    let nextScreen = layout.happyPath[currentSection].pages[currentScreen];

    if (nextScreen === 'next') {
        currentSection = layout.happyPath[currentSection].next;
        if (currentSection !== null) {
            nextScreen = Object.keys(layout.happyPath[currentSection].pages)[0];
        } else {
            nextScreen = null;
        }
    }

    return {
        currentSection: currentSection,
        currentScreen: nextScreen
    };
}

//todo refactor
export const getFields = ({ screen }) => {


    let dynamicScreens = ['introduction', 'diagnosis', 'last10', 'last5', 'last2', 'medical', 'misc'];

    let screenFields = {
        'landing': { first_name: "unknown", last_name: "unknown", email: "unknown", phone: "unknown", privacy: "unknown" },
        'welcome': { first_name: "unknown", last_name: "unknown", email: "unknown", phone: "unknown", privacy: "unknown" },
        'review': { first_name: "unknown", last_name: "unknown", email: "unknown", phone: "unknown" },
        'address': { address1: "unknown", city: "unknown", province_current: "unknown", postal_code: "unknown", province_birth: "unknown" },
        'beneficiary': { beneficiary_name: "unknown", /*beneficiary_relationship: "unknown",*/ beneficiary_minor: "unknown", beneficiary_trustee: "unknown", beneficiary_contingent_name: "unknown", beneficiary_contingent_relationship: "unknown" },
        'quoter': { dob: "unknown", gender: "unknown", facevalue: "unknown" },
        'payment': { payment_transit: "unknown", payment_account: "unknown", payment_address: "unknown", payment_bank: "unknown", payment_day: "unknown" },
        'general': { physical_height: "unknown", physical_weight: "unknown", dob: "unknown", A4350: "unknown", A4351: "unknown" },
        'occupation': { A4299: "unknown" },
        'consent': { confirm: "unknown" },
        'policy': { download: "unknown" }
    };
    for (let dynamicScreen of dynamicScreens) {
        screenFields[dynamicScreen] = {};

        for (let question of productQuestions[dynamicScreen]) {

            screenFields[dynamicScreen]["A" + question.id] = "unknown";
        }
    }


    return (screenFields[screen]);

}

//todo refactor
export const validateScreenFields = ({ screen, session }) => {


    let dynamicScreens = ['introduction', 'diagnosis', 'last10', 'last5', 'last2', 'medical', 'misc'];

    let screenFields = {
        'landing': ['first_name', 'last_name', 'email', 'phone', 'privacy'],
        'welcome': ['first_name', 'last_name', 'email', 'phone', 'privacy'],
        'review': ['first_name', 'last_name', 'email', 'phone'],
        'address': ['address1', 'city', 'province_current', 'postal_code', 'province_birth'],
        'beneficiary': ['beneficiary_name', 'beneficiary_relationship', 'beneficiary_minor', 'beneficiary_trustee', 'beneficiary_contingent_name', 'beneficiary_contingent_relationship'],
        'quoter': ['age', 'facevalue'],
        'payment': ['payment_transit', 'payment_account', 'payment_address', 'payment_bank', 'payment_day'],
        'general': ['physical_height', 'physical_weight', 'dob', 'A4350', 'A4351'],
        'occupation': ['A4299'],
        'consent': ['confirm'],
        'policy': ['download']
    };

    for (let dynamicScreen of dynamicScreens) {
        screenFields[dynamicScreen] = [];

        for (let question of productQuestions[dynamicScreen]) {
            screenFields[dynamicScreen].push("A" + question.id);
        }
    }

    let validFields = [];
    let invalidFields = [];

    if (screen && screenFields[screen]) {

        for (let field of screenFields[screen]) {
            if (undefined === session[field] || "" === session[field]) {
                invalidFields.push(field);
            } else {

                switch (field) {
                    case "payment_transit":
                        if (5 === session[field].length) {
                            validFields.push(field);
                        } else {
                            invalidFields.push(field);
                        }
                        break;
                    case "payment_account":
                        if (4 < session[field].length) {
                            validFields.push(field);
                        } else {
                            invalidFields.push(field);
                        }
                        break;
                    case "payment_bank":
                        if (3 === session[field].length) {
                            validFields.push(field);
                        } else {
                            invalidFields.push(field);
                        }
                        break;
                    case "dob":
                        if (10 === session[field].length) {
                            validFields.push(field);
                        } else {
                            invalidFields.push(field);
                        }
                        break;
                    case "email":
                        if (isEmail({ contactInfo: session[field] })) {
                            validFields.push(field);
                        } else {
                            invalidFields.push(field);
                        }
                        break;
                    case "phone":
                        if (isPhoneNumber({ contactInfo: session[field] })) {
                            validFields.push(field);
                        } else {
                            invalidFields.push(field);
                        }
                        break;
                    case "privacy":
                        if ("Yes" === session[field]) {
                            validFields.push(field);
                        } else {
                            invalidFields.push(field);
                        }
                        break;
                    default:
                        validFields.push(field);
                        break;
                }

            }

        }

    }

    return (
        { valid: validFields, invalid: invalidFields }
    );
}

export const qualify = ({ session }) => {

    let disqualified = false;
    let reasons = [];
    let unhappyPath = '';
    if (undefined !== session) {
        for (let category of ['mismatch', 'unqualified']) {
            for (let [k, v] of Object.entries(session)) {
                if (disqualify[category].yes.includes(k)) {
                    if (["Yes", "yes"].includes(v)) {
                        //onsole.info("Disqualified", k, v);
                        disqualified = true;
                        unhappyPath = category;
                        if (!reasons.includes(k)) {
                            reasons.push(k);
                        }
                    }
                }
                if (disqualify[category].no.includes(k)) {
                    if (["No", "no"].includes(v)) {
                        //onsole.info("Disqualified", k, v);
                        disqualified = true;
                        unhappyPath = category;
                        if (!reasons.includes(k)) {
                            reasons.push(k);
                        }
                    }
                }
                if (undefined !== disqualify[category].value[k]) {
                    if (disqualify[category].value[k].includes(v)) {
                        disqualified = true;
                        unhappyPath = category;
                        if (!reasons.includes(k)) {
                            reasons.push(k);
                        }
                    }
                }
            }
        }
        if (!qualifyPhysical({ feet: session.physical_feet, inches: session.physical_inches, kg: session.physical_kg })) {
            disqualified = true;
            reasons.push('physical');
            unhappyPath = 'mismatch';
        }
    }

    // if (disqualified) {
    //     //onsole.info("Disqualified", reasons);
    // }

    return ({
        disqualified: disqualified,
        reasons: reasons,
        unhappyPath: unhappyPath
    });

}

const qualifyPhysical = ({ feet, inches, kg }) => {

    let qualified = false;

    if (undefined !== feet && undefined !== inches && undefined !== kg) {

        let bracketId = feet.toString() + "'" + inches.toString();
        let kgVal = parseInt(kg);

        if (!isNaN(kgVal)) {
            for (let bracket of settings.weights) {
                if (bracketId == bracket.ft) {
                    if (kgVal >= bracket.kg_low) {
                        if (kgVal <= bracket.kg_high) {
                            qualified = true;
                        }
                    }
                }
            }
        }
    }
    return qualified;
}


export const prepareAnswers = ({ session }) => {

    //productQuestions
    let questions = {};
    for (let [k, answer] of Object.entries(session)) {
        if (k.startsWith("A")) {
            let questionId = k.replace("A", "");
            if (!isNaN(parseInt(questionId))) {
                questions["question_" + questionId] = {};
                if (typeof answer === 'object') {
                    questions["question_" + questionId].id = answer.id;
                    questions["question_" + questionId].text = answer.text;
                } else {
                    for (let [bundleId, bundle] of Object.entries(productQuestions.bundled)) {
                        if (undefined !== bundle.questions) {
                            for (let bundleQuestion of bundle["questions"]) {
                                if (undefined !== bundleQuestion.id && questionId === bundleQuestion.id) {
                                    if (undefined !== bundleQuestion.choices) {
                                        for (let [choiceId, choice] of Object.entries(bundleQuestion.choices)) {
                                            if (answer === choice.id || answer === choice.copy) {
                                                questions["question_" + questionId].id = choice.id;
                                                questions["question_" + questionId].text = choice.copy;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    return questions;

}