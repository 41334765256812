import {
  useContext,
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import {
  Form,
  InputGroup,
} from 'react-bootstrap';
import {
  BsInfoCircle,
  BsTelephone,
} from 'react-icons/bs';
import { CgCloseR } from 'react-icons/cg';

import {
  BrandingContext,
  LayoutContext,
  SessionContext,
} from '../libs/contexts.js';
import { asNumber } from '../libs/i18n.js';

export const TextControl = ({ field, placeholder, onChange, required, type, label, title, helpMessage, mustRevalidate, minValue, maxValue, validateInput, slider }) => {

    const { session, setSession } = useContext(SessionContext);
    const { branding } = useContext(BrandingContext);
    const { layout } = useContext(LayoutContext);
    const [helpVisible, setHelpVisible] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {

        if (layout && layout.fields) {
            if ("error" === layout.fields[field]) {
                setHasErrors(true);
            } else {

                setHasErrors(false);


            }
        } else {
            setHasErrors(false);
        }


    }, [session[field], layout.fields]);

    useEffect(() => {

        if (mustRevalidate) {
            if (undefined === session[field] || '' === session[field] || layout?.validation?.invalid?.includes(field)) {
                setHasErrors(true);
            } else {
                setHasErrors(false);
            }
        }


    }, [mustRevalidate, session[field], layout?.validation?.invalid]);

    const hasChanged = (e) => {
        let value = e.target.value;

        if (!validateInput || validateInput(value)) {
            let newSession = {};
            newSession[field] = value;
            setSession(newSession);
            onChange({ id: field, value: value });
        }
    }

    const onHelpToggle = () => {
        setHelpVisible(!helpVisible);
    }


    return (

        <>
            <Form.Group className="mb-3" controlId={ field }>
                { label && "show" === branding.formLabels &&
                    <>
                        <Form.Label>{ label }

                        </Form.Label>
                        <>
                            { helpMessage &&
                                <>
                                    { helpVisible ?
                                        <CgCloseR id={ "help-close-" + field } className="float-end float-right clicker text-muted" onClick={ onHelpToggle } />
                                        :
                                        <BsInfoCircle id={ "help-open-" + field } className="float-end float-right clicker" onClick={ onHelpToggle } />
                                    }
                                </>
                            }
                        </>
                    </>
                }

                <div className="mb-3" key={ field }>

                    { "text" === type &&
                        <BasicTextControl title={ title } helpMessage={ helpMessage } value={ session[field] } placeholder={ placeholder } hasErrors={ hasErrors } hasChanged={ hasChanged } required={ required } />
                    }
                    { "email" === type &&
                        <EmailAddressControl title={ title } helpMessage={ helpMessage } value={ session[field] } placeholder={ placeholder } hasErrors={ hasErrors } hasChanged={ hasChanged } required={ required } />
                    }
                    { "phone" === type &&
                        <PhoneNumberControl title={ title } helpMessage={ helpMessage } value={ session[field] } placeholder={ placeholder } hasErrors={ hasErrors } hasChanged={ hasChanged } required={ required } />
                    }
                    { "dollar" === type &&
                        <DollarControl setHelpVisible={ setHelpVisible } title={ title } helpMessage={ helpMessage } value={ session[field] } placeholder={ placeholder } minValue={ minValue } maxValue={ maxValue } slider={ slider } hasErrors={ hasErrors } hasChanged={ hasChanged } required={ required } />
                    }
                    { "number" === type &&
                        <NumberControl title={ title } helpMessage={ helpMessage } value={ session[field] } placeholder={ placeholder } minValue={ minValue } maxValue={ maxValue } hasErrors={ hasErrors } hasChanged={ hasChanged } required={ required } />
                    }
                    { "dob" === type &&
                        <DobControl title={ title } helpMessage={ helpMessage } value={ session[field] } placeholder={ placeholder } hasErrors={ hasErrors } hasChanged={ hasChanged } required={ required } />
                    }
                    { "notes" === type &&
                        <LongTextControl title={ title } helpMessage={ helpMessage } value={ session[field] } placeholder={ placeholder } hasErrors={ hasErrors } hasChanged={ hasChanged } required={ required } />
                    }

                </div>


                { helpMessage && helpVisible &&
                    <>
                        <Form.Text id={ "help-label-" + field } className="text-muted">
                            { helpMessage }
                        </Form.Text>
                    </>
                }
            </Form.Group>

        </>

    )

}

const BasicTextControl = ({ title, value, placeholder, hasErrors, hasChanged, required, helpMessage }) => {



    return (
        <Form.Control title={ title } className={ hasErrors ? "is-invalid" : "" } type="text" placeholder={ placeholder } value={ value || '' } required={ required } onChange={ hasChanged } onPaste={ hasChanged } />
    )

}

const DobControl = ({ title, value, placeholder, hasErrors, hasChanged, required, helpMessage }) => {


    const reformatDate = (rawValue) => {

        let dateValue = "";
        let newValue = rawValue.replace(/\D/g, '').slice(0, 8);
        if (8 === newValue.length) {
            dateValue = newValue.slice(0, 4) + '-' + newValue.slice(4, 6) + '-' + newValue.slice(6, 8);
        }

        return dateValue;

    }

    const isRealDate = (rawValue) => {

        let isValid = false;
        let dateValue = reformatDate(rawValue);
        if (10 === dateValue.length) {
            let dateObj = moment(dateValue);
            if (dateObj.isValid()) {
                isValid = true;
            }
        }
        return isValid;

    }

    const onValueChange = (e) => {
        let rawValue = e.target.value.toString();
        let newValue = rawValue.replace(/[^0-9\-]/g, '').slice(0, 10);
        //value = newValue;
        if (isRealDate(newValue)) {
            newValue = reformatDate(newValue);
        }
        hasChanged({
            target: {
                value: newValue
            }
        })


    }

    const onFocus = () => {
        hasChanged({
            target: {
                value: ''
            }
        })
    }

    return (
        <>
            <InputGroup>
                <Form.Control onFocus={ () => onFocus() } title={ title } className={ hasErrors ? "is-invalid" : "" } type="text" placeholder={ placeholder } value={ value || '' } required={ required } onChange={ onValueChange } onPaste={ onValueChange } />
            </InputGroup>
        </>

    )

}



const DollarControl = ({ title, value, minValue, maxValue, hasErrors, placeholder, hasChanged, required, slider, setHelpVisible }) => {

    const [values, setValues] = useState('');


    useEffect(() => {

        if (value) {
            let valueStr = "";
            if (undefined !== value) {
                valueStr = value.toString();
            }
            let newValues = {
                text: valueStr.replace(/\D/g, ''),
                slider: 0
            };

            if (parseInt(newValues.text)) {
                newValues.slider = parseInt(newValues.text);
                newValues.text = asNumber({ value: newValues.text });
            }

            setValues(newValues);

            if (newValues.slider > 0) {

                if (newValues.slider < minValue || newValues.slider > maxValue) {
                    setHelpVisible(true);
                } else {
                    setHelpVisible(false);
                }


            }

        }


    }, [value]);



    const onKeyDown = (e) => {

    }

    const onKeyUp = (e) => {

    }

    const onValueChange = (e) => {


        let newValue = e.target.value;
        if (undefined === newValue) {
            newValue = 0;
        }

        let baseNumber = e.target.value.replace(/\D/g, '');

        let newValues = {
            text: asNumber({ value: baseNumber }),
            slider: parseInt(baseNumber)
        };


        setValues(newValues);
        value = newValues.slider;

        hasChanged({
            target: {
                value: newValues.slider
            }
        });

    }

    return (
        <>

            <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control title={ title } className={ hasErrors ? "is-invalid dollar-control" : "dollar-control" } required={ required } type="text" placeholder={ placeholder } value={ values.text || '' } onPaste={ hasChanged } onKeyUp={ onKeyUp } onKeyDown={ onKeyDown } onChange={ (e) => onValueChange(e) } />
            </InputGroup>
            { "hide" !== slider &&
                <Form.Range style={ { width: "100%" } } min={ minValue } max={ maxValue } step="1000" value={ values.slider || minValue } onChange={ (e) => onValueChange(e) } />
            }

        </>

    )

}


const EmailAddressControl = ({ title, value, placeholder, hasErrors, hasChanged, required, helpMessage }) => {

    return (
        <>
            <InputGroup>
                <InputGroup.Text>@</InputGroup.Text>
                <Form.Control title={ title } className={ hasErrors ? "is-invalid" : "" } type="email" placeholder={ placeholder } value={ value || '' } required={ required } onChange={ hasChanged } onPaste={ hasChanged } />
            </InputGroup>
        </>

    )

}


const LongTextControl = ({ title, value, placeholder, hasErrors, hasChanged, required, helpMessage }) => {

    return (
        <Form.Control as="textarea" rows={ 3 } title={ title } className={ hasErrors ? "is-invalid" : "" } type="text" placeholder={ placeholder } value={ value || '' } required={ required } onChange={ hasChanged } onPaste={ hasChanged } />
    )

}



const NumberControl = ({ title, value, minValue, maxValue, hasErrors, hasChanged, placeholder, required }) => {

    const [controlValue, setControlValue] = useState('');

    useEffect(() => {

        if (value) {
            let valueStr = "";
            if (undefined !== value) {
                valueStr = value.toString();
            }
            let newControlValue = valueStr.replace(/\D/g, '');


            if (parseInt(newControlValue)) {
                newControlValue = parseInt(newControlValue);
                if (!isNaN(newControlValue)) {
                    if (newControlValue >= minValue) {

                        if (newControlValue <= maxValue) {
                            setControlValue(newControlValue);
                        } else {
                            setControlValue(maxValue);
                        }

                    } else {
                        setControlValue(minValue);
                    }
                } else {
                    setControlValue("");
                }
            } else {
                setControlValue("");
            }

        }


    }, [value]);



    const onKeyDown = (e) => {

    }

    const onKeyUp = (e) => {

    }

    const onValueChange = (e) => {


        let newValue = e.target.value;
        if (undefined === newValue) {
            newValue = "";
        }

        let newControlValue = e.target.value.replace(/\D/g, '');

        if (parseInt(newControlValue)) {
            newControlValue = parseInt(newControlValue);
            if (!isNaN(newControlValue)) {

                if (newControlValue >= minValue) {
                    if (newControlValue > maxValue) {
                        newControlValue = maxValue;
                    }

                } else {
                    newControlValue = minValue;
                }

            } else {
                newControlValue = "";
            }
        } else {
            newControlValue = "";
        }

        setControlValue(newControlValue);

        hasChanged({
            target: {
                value: newControlValue
            }
        });

    }

    return (
        <>

            <Form.Control title={ title } className={ hasErrors ? "is-invalid" : "" } required={ required } type="text" placeholder={ placeholder } value={ controlValue || '' } onPaste={ hasChanged } onKeyUp={ onKeyUp } onKeyDown={ onKeyDown } onChange={ (e) => onValueChange(e) } />

        </>

    )

}


const PhoneNumberControl = ({ title, value, placeholder, hasErrors, hasChanged, required, helpMessage }) => {


    const onKeyDown = (e) => {
        /*if (8 === e.keyCode || 46 === e.keyCode) {
            setCursorPosition(e.target.selectionStart);
        }*/
    }

    const onKeyUp = (e) => {
        /*
        if (8 === e.keyCode || 46 === e.keyCode) {
            if (cursorPosition) {
                e.target.selectionStart = cursorPosition;
                e.target.selectionEnd = cursorPosition;
                setCursorPosition(0);
            }
        }*/
    }

    return (
        <>
            <InputGroup>
                <InputGroup.Text><BsTelephone /></InputGroup.Text>
                <Form.Control title={ title } className={ hasErrors ? "is-invalid" : "" } type="text" placeholder={ placeholder } value={ value || '' } required={ required } onChange={ hasChanged } onPaste={ hasChanged } onKeyUp={ onKeyUp } onKeyDown={ onKeyDown } />
            </InputGroup>
        </>

    )

}


const PostalCodeControl = ({ title, value, placeholder, hasErrors, hasChanged, required }) => {

    const onKeyDown = (e) => {
        /*if (8 === e.keyCode || 46 === e.keyCode) {
            setCursorPosition(e.target.selectionStart);
        }*/
    }

    const onKeyUp = (e) => {
        /*
        if (8 === e.keyCode || 46 === e.keyCode) {
            if (cursorPosition) {
                e.target.selectionStart = cursorPosition;
                e.target.selectionEnd = cursorPosition;
                setCursorPosition(0);
            }
        }*/
    }

    return (
        <>
            <InputGroup>
                <InputGroup.Text><BsTelephone /></InputGroup.Text>
                <Form.Control title={ title } className={ hasErrors ? "is-invalid" : "" } type="text" placeholder={ placeholder } value={ value || '' } required={ required } onChange={ hasChanged } onPaste={ hasChanged } onKeyUp={ onKeyUp } onKeyDown={ onKeyDown } />
            </InputGroup>
        </>

    )

}