import { detect } from 'detect-browser';
import moment from 'moment-timezone';

import {
  getBrandName,
  getBroker,
  getCampaign
} from './branding';
import { asCurrency } from './i18n';
import { getUniqueId } from './id';

export const ageFromDob = ({ dobStr }) => {

    return ageLast({ dobStr: dobStr });
    /*
    if ("age last" === config.ageMethod) {
        console.log("Using age last");
        return ageLast({ dobStr: dobStr });
    } else {
        console.log("Using age nearest");
        return ageNearest({ dobStr: dobStr });
    }*/

}

export const ageLast = ({ dobStr }) => {
    // Create a new Date object for the current date
    const today = new Date();
    // Create a new Date object for the date of birth
    const birthDate = new Date(dobStr);
    // Calculate the difference in years
    let age = today.getFullYear() - birthDate.getFullYear();
    // Adjust the age if the birth date hasn't occurred yet this year
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}


export const ageNearest = ({ dobStr }) => {

    let age;

    // we want to calculate the age using same timezone as Approve server
    let dob = moment.tz(dobStr, "America/Winnipeg");
    let now = moment.tz("America/Winnipeg");

    if (dob && dob.isValid()) {

        let years = moment.duration(now.diff(dob)).years();
        let months = moment.duration(now.diff(dob)).months();
        let days = moment.duration(now.diff(dob)).days();

        if (months >= 6) { // less than 6 months delta means the previous birthday is closer

            if (6 === months) {

                let dateParts = dobStr.split('-');
                dateParts.pop();
                const hoursInMonth = 24 * Array.from(Array(moment(dateParts.join('-'), "YYYY-MM").daysInMonth()).keys()).length;

                // we check if there are more half the hours left in the month 
                if ((days * 24) / 2.0 < hoursInMonth / 2.0) {
                    years++;
                }


            } else {
                // more than 6 months delta in the year, so it's closer to the next birthday
                years++;
            }

        }

        age = years;

    }

    return age;

}


export const getInitialQuote = ({ fromJump }) => {

    const browser = detect();
    const campaign = getCampaign();
    let now = Date.now();

    let initialQuote = {
        brand: getBrandName(),
        broker: getBroker(),
        started: now,
        date: moment().format('YYYY-MM-DD'),
        outcome: 'started',
        utm_campaign: campaign.utm_campaign,
        utm_source: campaign.utm_source,
        utm_medium: campaign.utm_medium,
        browser_name: browser.name,
        browser_version: browser.version,
        os: browser.os,
        latest_step: 'landing',
        latest_ts: now,
        landing_start: now,
        disqualified: {}
    };


    if (!fromJump) {
        initialQuote.id = getUniqueId();
        initialQuote.quote = initialQuote.id;
    }

    return initialQuote;


}


export const getOffers = ({ age, gender, smoker, facevalue, insuranceData }) => {


    let plans = insuranceData.plans;
    //let riders = insuranceData.riderRates;

    let riders = {
        term10: insuranceData.rider10,
        term20: insuranceData.rider20
    };
    let products = {
        term10: insuranceData.term10,
        term20: insuranceData.term20
    };

    let result = {};


    let eligibility = {
        age: false,
        facevalue: false
    };

    //todo what if the terms have different eligibility
    for (let [product, specifications] of Object.entries(plans)) {
        if (specifications.minage && specifications.minage <= age) {
            if (specifications.maxage && specifications.maxage >= age) {
                eligibility.age = true;
            }
        }
        if (specifications.minface && specifications.minface <= facevalue) {
            if (specifications.maxface && specifications.maxface >= facevalue) {
                eligibility.facevalue = true;
            }
        }
    }

    result.eligibility = eligibility;


    facevalue = parseInt(facevalue.toString()); //meh

    let segment = [age, gender.toUpperCase(), smoker.toString()].join('#');

    result.segment = segment;

    let offers = {};

    if (eligibility.age && eligibility.facevalue) {
        for (let [product, rates] of Object.entries(products)) {
            if (undefined !== rates[segment]) {
                if (undefined !== facevalue && !isNaN(facevalue)) {
                    for (let band of rates[segment]) {
                        if (band.lowerlimit <= facevalue) {
                            if (band.upperlimit >= facevalue) {
                                if (plans[product] && plans[product].mode) {
                                    offers[product] = {
                                        base: {},
                                        rider: {}
                                    };
                                    let mode = parseFloat(plans[product].mode);
                                    let rawAmount = parseFloat(facevalue / 1000 * parseFloat(band.rate).toFixed(2) * mode);
                                    offers[product].base = rawAmount;
                                }
                            }
                        }
                    }
                }

            }
        }
        for (let [product, rates] of Object.entries(riders)) {

            if (undefined !== rates[segment]) {
                if (undefined !== facevalue && !isNaN(facevalue)) {
                    for (let band of rates[segment]) {
                        if (band.lowerlimit <= facevalue) {
                            console.info("Lower limit", band.lowerlimit, facevalue);
                            if (band.upperlimit >= facevalue) {
                                console.info("upper limit", band.upperlimit, facevalue);
                                if (plans[product] && plans[product].mode) {
                                    console.info("got plan", plans[product]);
                                    let mode = parseFloat(plans[product].mode);
                                    let rawAmount = parseFloat(facevalue / 1000 * parseFloat(band.rate).toFixed(2) * mode);
                                    console.log(facevalue / 1000, parseFloat(band.rate).toFixed(2), mode);
                                    offers[product].rider = rawAmount;
                                }
                            }
                        }
                    }

                }
            }
        }

        result.offers = offers;
    } else {
        //onsole.info("Not eligible", eligibility);
    }

    return result;


}

export const calculateScenarios = ({ currentTerm, rider, offers, facevalue }) => {

    let scenarios;

    if (offers && currentTerm) {

        if (offers[currentTerm]) {
            let baseAmount = offers[currentTerm].base;
            let totalAmount = baseAmount;
            let withRider = baseAmount + offers[currentTerm].rider;
            if ("Yes" === rider) {
                totalAmount = withRider;
            }

            let premiumOptions = {
                premium: asCurrency({ amount: totalAmount }),
                premium_base: asCurrency({ amount: baseAmount }),
                premium_rider: asCurrency({ amount: offers[currentTerm].rider }),
                premium_difference: asCurrency({ amount: offers["term20"].base - offers["term10"].base }),
                premium_coverage: asCurrency({ amount: facevalue, dropPennies: true })
            };

            premiumOptions.alternative_rider = "Yes" === rider ? asCurrency({ amount: baseAmount }) : asCurrency({ amount: withRider });

            if ("term10" === currentTerm) {
                premiumOptions.alternative_action = "extend";
                premiumOptions.alternative_term = "term20";
                premiumOptions.alternative_years = "20";
            } else {
                premiumOptions.alternative_action = "shorten";
                premiumOptions.alternative_term = "term10";
                premiumOptions.alternative_years = "10";
            }
            if (offers[premiumOptions.alternative_term]) {
                let alternativeBase = offers[premiumOptions.alternative_term].base;
                let alternativeTotal = alternativeBase;
                if ("Yes" === rider) {
                    alternativeTotal = alternativeBase + offers[premiumOptions.alternative_term].rider;
                }
                premiumOptions.alternative_base = asCurrency({ amount: alternativeBase });
                premiumOptions.alternative_total = asCurrency({ amount: alternativeTotal });


            }

            scenarios = premiumOptions;

        }

    }

    return scenarios;

}