import {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { TextControl } from '../controls/text';
import {
  AccordionBasic,
  RadioAccordion,
  SimpleCarousel,
} from '../controls/wizards';
import {
  asPhoneNumber,
  isEmail,
  isPhoneNumber,
} from '../libs/contact';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
  stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import { getNextLayout } from '../libs/workflow';
import * as productQuestions from '../products/quickterm-questions';
import Navigation from './navigation';

export const Misc = () => {

  const { layout, setLayout } = useContext(LayoutContext);

  const navigate = useNavigate();

  useEffect(() => {

    scrollUp();
    setLayout({
      screenReady: true,
      currentScreen: 'misc',
      currentSection: 'medicalinfo',
    });


  }, []);

  const validateScreen = () => {

    let requirements = { ...layout.requirements };
    requirements.misc = "pass";

    let newLayout = {
      requirements: requirements
    };
    setLayout(newLayout);
  }


  const moveForward = (e) => {

    let newLayout = getNextLayout(layout);

    setLayout(newLayout);
    navigate('/' + newLayout.currentScreen);

  }



  return (
    <>
      <Container>
        <Row className="mt-4 mb-4">
            <Col>
                <Navigation></Navigation>
            </Col>
        </Row>
        <Row className="mt-2">
            <Col className="col-lg-12 col-xl-10">
            <SimpleCarousel onCompleted={moveForward} questions={productQuestions.misc} title="Please answer:" />
            </Col>
        </Row>
      </Container>
    </>
  )

}

