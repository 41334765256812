import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';

import * as screens from '../components/main.jsx';
import { Page } from '../layouts/page.jsx';
import { QuickTermQuoter } from '../products/quickterm-quoter.jsx';

export const QuickTermRoutes = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Page />}>
                    <Route index element={<screens.DefaultLanding />} />
                    <Route path="welcome" element={<screens.DefaultLanding />} />
                    <Route path="quoter" element={<QuickTermQuoter />} />
                    <Route path="general" element={<screens.General />} />
                    <Route path="introduction" element={<screens.Introduction />} />
                    <Route path="address" element={<screens.Address />} />
                    <Route path="diagnosis" element={<screens.Diagnosis />} />
                    <Route path="last10" element={<screens.Last10 />} />
                    <Route path="last5" element={<screens.Last5 />} />
                    <Route path="last2" element={<screens.Last2 />} />
                    <Route path="medical" element={<screens.Medical />} />
                    <Route path="occupation" element={<screens.Occupation />} />
                    <Route path="misc" element={<screens.Misc />} />
                    <Route path="beneficiary" element={<screens.Beneficiary />} />
                    <Route path="payment" element={<screens.Payment />} />
                    <Route path="consent" element={<screens.Consent />} />
                    <Route path="policy" element={<screens.Policy />} />
                    <Route path="review" element={<screens.Review />} />
                    <Route path="end" element={<screens.EjectionScreen />} />
                    <Route path="thankyou" element={<screens.UnqualifiedLead />} />
                    <Route path="opportunities" element={<screens.ProductMismatch />} />
                    <Route path="*" element={<screens.DefaultLanding />} />
                </Route>
            </Routes>
        </BrowserRouter>

    )

}

