import {
    useContext,
} from 'react';

import {
    Button,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import {
    useNavigate,
} from 'react-router-dom';

import {
    LayoutContext,
} from '../libs/contexts.js';
import { getNextLayout } from '../libs/workflow';

export const PreviousNextButtons = ({ hasPrevious, enabled, callback }) => {

    const { layout, setLayout } = useContext(LayoutContext);

    const navigate = useNavigate();

    const moveForward = () => {
        let newLayout = getNextLayout(layout);
    
        setLayout(newLayout);
        navigate('/' + newLayout.currentScreen);
    }

    const nextAction = () => {
        if (enabled) {
            moveForward();
        } else {
            callback({ forceValidation: true });
        }
    }

    return (

        <>
            <Container fluid className='text-left px-0 mx-0'>
                <Row>
                    {hasPrevious &&
                        <Col>
                            <Button variant="outline-secondary" className='btn-lg'>Back</Button>
                        </Col>
                    }
                    <Col className='text-left px-0 mx-0'>
                        <Button onClick={nextAction} variant={enabled ? "outline-primary" : "outline-secondary"} className='btn-lg'>Continue</Button>
                    </Col>
                </Row>
            </Container>
        </>

    )

}
