import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {
  useNavigate
} from 'react-router-dom';
import {
  LayoutContext,
  SessionContext,
} from '../libs/contexts';
import {
  useContext,
  useState,
  useEffect
} from 'react';
import { BsDash, BsArrowClockwise } from "react-icons/bs";
import { FaCheck } from 'react-icons/fa';

export const Navigation = () => {
  const { layout, setLayout } = useContext(LayoutContext);
  const { session, setSession } = useContext(SessionContext);
  const [happyPath, setHappyPath] = useState({});
  const [currentStep, setCurrentStep] = useState("Step *");
  const [enabledSections, setEnabledSections] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    if (session.happyPath) {
      setHappyPath(session.happyPath);
      setEnabledSections(getEnabledSections(layout.currentSection));
      setCurrentStep(`Step ${Object.keys(session.happyPath).indexOf(layout.currentSection) + 1}/${Object.keys(session.happyPath).length}`);
    }
  }, [session.happyPath, layout.currentSection]);

  const handleSelect = (route) => {
    navigate(route);
  };

  const getEnabledSections = (currentSection) => {
    let enabledSections = [];
    let section = Object.keys(session.happyPath)[0];

    while (section) {
      if (section === currentSection) break;
      enabledSections.push(section);
      section = session.happyPath[section].next;
    }

    return enabledSections;
  };


  return (
    <DropdownButton size="sm" id="navigation" title={currentStep}>
      {happyPath && Object.keys(happyPath).map((sectionKey) => {
        const section = happyPath[sectionKey];
        const firstPageKey = Object.keys(section.pages)[0];
        const route = '/' + firstPageKey;

        return (
          <Dropdown.Item
            key={sectionKey}
            onClick={() => handleSelect(route)}
            disabled={!enabledSections.includes(sectionKey)}
          >
            {layout.currentSection === sectionKey ? (
                                <BsArrowClockwise/>
                            ) : enabledSections.includes(sectionKey) ? (
                                <FaCheck className='green-check' />
                            ) : (
                                <BsDash />
                            )} {section.name}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
}

export default Navigation;