import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Col,
    Container,
    Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { ProvinceSelect } from '../controls/select';
import { TextControl } from '../controls/text';
import {
    LayoutContext,
    SessionContext,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import Navigation from './navigation';

export const Address = () => {

    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const [mustRevalidate, setMustRevalidate] = useState(false);


    useEffect(() => {
        scrollUp();
        setLayout({ currentScreen: 'address', currentSection: 'address', screenReady: false });
    }, []);


    const onChange = ({ id, value }) => {

        setLayout({ lastValidated: Date.now() });

        if ("postal_code" === id) {
            if (value && value.length > 0) {
                value = value.toUpperCase();
                setSession({
                    postal_code: value,
                    asOf: Date.now()
                });
            }
        }

        if ("province_check" === id) {
            if ("Yes" === value) {
                //onsole.info("Sync province");
                setSession({
                    province_birth: session.province_current
                });
            }
        }
        if ("province_current" === id) {
            if ("Yes" === value) {
                setSession({
                    province_birth: session.province_current
                });
            }
        }

    };

    const validateScreen = () => {

        setMustRevalidate(true);
    }


    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <Navigation></Navigation>
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>Tell us about yourself</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <ProvinceSelect field="province_current" placeholder="Province of residence" mustRevalidate={mustRevalidate} onChange={onChange} />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="City" title="Please provide us with your city of residence" field="city" placeholder="City" mustRevalidate={mustRevalidate} onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Street address" title="Please provide us with your current address" field="address1" mustRevalidate={mustRevalidate} placeholder="Address" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Postal code" title="Please provide us with your postal code" field="postal_code" mustRevalidate={mustRevalidate} placeholder="Postal code" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Country" title="Please provide us with your country of residence" field="country" mustRevalidate={mustRevalidate} placeholder="Country" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <RadioControl label="Were you born in your current province of residence?" type="yesno" mustRevalidate={mustRevalidate} onChange={onChange} field="province_check" />
                    </Col>
                </Row>
                {"No" === session.province_check &&
                    <Row className="mt-2">
                        <Col className="col-lg-12 col-xl-10">
                            <ProvinceSelect field="province_birth" placeholder="Province at birth" mustRevalidate={mustRevalidate} onChange={onChange} />
                        </Col>
                    </Row>
                }
                <Row className="mt-4">
                    <Col>
                        {layout &&
                            <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}
