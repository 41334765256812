import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Col,
    Container,
    Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { RelationshipFinder } from '../controls/search';
import { TextControl } from '../controls/text';
import {
    BrandingContext,
    LayoutContext,
    SessionContext,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import { prepareAnswers } from '../libs/workflow';
import * as references from '../reference/approve.jsx';
import Navigation from './navigation.jsx';

export const Beneficiary = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const { answers, setAnswers } = useState(false);
    const [beneficiaryRelationship, setBeneficiaryRelationship] = useState("");
    const [validBeneficiaryRelationship, setValidBeneficiaryRelationship] = useState(false);
    const [contingentBeneficiaryRelationship, setContingentBeneficiaryRelationship] = useState("");
    const [validContingentBeneficiaryRelationship, setValidContingentBeneficiaryRelationship] = useState(false);

    const [mustRevalidate, setMustRevalidate] = useState(false);
    const relationshipValues = references.relationships.map(relation => relation.name);


    useEffect(() => {

        scrollUp();
        setLayout({ currentScreen: 'beneficiary', currentSection: 'beneficiary', screenReady: false });
        let newAnswers = prepareAnswers({ session: session });
        setSession(newAnswers);

    }, []);


    useEffect(() => {

        if (relationshipValues.includes(beneficiaryRelationship)) {
            setValidBeneficiaryRelationship(true);
        } else {
            setValidBeneficiaryRelationship(false);
        }

        if (relationshipValues.includes(contingentBeneficiaryRelationship)) {
            setValidContingentBeneficiaryRelationship(true);
        } else {
            setValidContingentBeneficiaryRelationship(false);
        }

    }, [beneficiaryRelationship, contingentBeneficiaryRelationship]);



    const onChange = (e) => {
        if (e.id === undefined) {
            if (e.target.placeholder && e.target.placeholder === "Relationship") {
                setBeneficiaryRelationship(e.target.value);
            } else if (e.target.placeholder && e.target.placeholder === "Contingent relationship") {
                setContingentBeneficiaryRelationship(e.target.value);
            }
        } else {
            if ("beneficiary_relationship" === e.id) {
                setBeneficiaryRelationship(e.value.label);
                setSession({
                    beneficiary_relationship: e.value.label,
                    beneficiary_relationship_id: e.value.id
                });
            }

            if ("beneficiary_contingent_relationship" === e.id) {
                setContingentBeneficiaryRelationship(e.value.label);
                setSession({
                    beneficiary_contingent_relationship: e.value.label,
                    beneficiary_contingent_relationship_id: e.value.id
                });
            }

            if ("beneficiary_minor" === e.id) {
                if ("No" === e.value) {
                    setSession({
                        beneficiary_trustee: "n/a"
                    });
                } else {
                    setSession({
                        beneficiary_trustee: ""
                    });
                }
            }
            if ("beneficiary_contingent" === e.id) {
                if ("No" === e.value) {
                    setSession({
                        beneficiary_contingent_name: "n/a",
                        beneficiary_contingent_relationship: "n/a",
                    });
                } else {
                    setSession({
                        beneficiary_contingent_name: "",
                        beneficiary_contingent_relationship: ""
                    });
                }
            }
        }

    };

    const validateScreen = (options) => {

        setMustRevalidate(true);

    }

    const initialSelectedBeneficiaryRelationship = () => {
        return session.beneficiary_relationship ? [{ id: session.beneficiary_relationship_id, name: session.beneficiary_relationship, label: session.beneficiary_relationship }] : [];
    }

    const initialSelectedContingentBeneficiaryRelationship = () => {
        return session.beneficiary_contingent_relationship ? [{ id: session.beneficiary_contingent_relationship_id, name: session.beneficiary_contingent_relationship, label: session.beneficiary_contingent_relationship }] : [];
    }

    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <Navigation></Navigation>
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>Beneficiary</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl mustRevalidate={mustRevalidate} label="Name of your beneficiary" title="Please provide us with the name of your beneficiary" field="beneficiary_name" placeholder="Name" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <RelationshipFinder initialValue={initialSelectedBeneficiaryRelationship()} hasErrors={mustRevalidate} label="Relationship" title="Please provide us with your relation with the beneficiary" field="beneficiary_relationship" placeholder="Relationship" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <RadioControl mustRevalidate={mustRevalidate} label="Is your beneficiary a minor?" type="yesno" helpMessage="Answer yes if your beneficiary is currently under 18 years old." onChange={onChange} field="beneficiary_minor" />
                    </Col>
                </Row>
                {"Yes" === session.beneficiary_minor &&
                    <Row className="mt-2">
                        <Col className="col-lg-12 col-xl-10">
                            <TextControl label="Trustee" title="The trustee of your beneficiary" field="beneficiary_trustee" placeholder="Trustee" onChange={onChange} required={true} type="text" />
                        </Col>
                    </Row>
                }

                <Row className="mt-2">
                    <Col>
                        <RadioControl mustRevalidate={mustRevalidate} label="Do you want to specify a contingent beneficiary?" type="yesno" onChange={onChange} field="beneficiary_contingent" />
                    </Col>
                </Row>
                {"Yes" === session.beneficiary_contingent &&
                    <>
                        <Row className="mt-2">
                            <Col className="col-lg-12 col-xl-10">
                                <TextControl label="Contingent beneficiary" title="The name of the contingent beneficiary" field="beneficiary_contingent_name" placeholder="Contingent name" onChange={onChange} required={true} type="text" />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="col-lg-12 col-xl-10">
                                <RelationshipFinder initialValue={initialSelectedContingentBeneficiaryRelationship()} hasErrors={mustRevalidate} label="Relationship with contingent beneficiary" title="Relationship with the contingent beneficiary" field="beneficiary_contingent_relationship" placeholder="Contingent relationship" onChange={onChange} required={true} type="text" />
                            </Col>
                        </Row>
                    </>
                }
                <Row className="mt-4">
                    <Col>
                        {layout &&
                            <PreviousNextButtons enabled={layout.screenReady && validBeneficiaryRelationship && ("Yes" === session.beneficiary_contingent ? validContingentBeneficiaryRelationship : true)} callback={validateScreen} />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}
