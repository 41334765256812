import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { TextControl } from '../controls/text';
import { sendEmailVerification } from '../libs/api';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
  stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import Navigation from './navigation';

export const Payment = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const [mustRevalidate, setMustRevalidate] = useState(false);


    useEffect(() => {

        scrollUp();
        setLayout({ currentScreen: 'payment', currentSection: 'payment', screenReady: false });


    }, []);

    const onChange = ({ id, value }) => {

        //

    };
    const validateScreen = (options) => {

        setMustRevalidate(true);

    }

    const validateFinanialInstitution = (input) => {
        const isNumber = /^[0-9]*$/.test(input);
        
        return isNumber && input.length <= 3;
    };

    const validateTransitNumber = (input) => {
        const isNumber = /^[0-9]*$/.test(input);
        
        return isNumber && input.length <= 5;
    };

    const validateAccountNumber = (input) => {
        const isValid = /^[0-9a-zA-Z]*$/.test(input);
        return isValid && input.length <= 12;
    };

    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <Navigation></Navigation>
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>Payment information</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl mustRevalidate={mustRevalidate} title="Financial Institution identifier (enter 3 digit code)" helpMessage="This is usually a 3-digit number, such as 001 for BMO." label="Bank institution identifier" field="payment_bank" placeholder="Financial Institution identifier (enter 3 digit code)" onChange={onChange} required={true} type="text" validateInput={validateFinanialInstitution} />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl mustRevalidate={mustRevalidate} title="Branch mailing address" label="Branch mailing address" field="payment_address" placeholder="Branch mailing address" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl mustRevalidate={mustRevalidate} title="Transit Number (enter 5 digits)" label="Transit number" field="payment_transit" placeholder="Transit Number (enter 5 digits)" onChange={onChange} required={true} type="text" validateInput={validateTransitNumber} />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl mustRevalidate={mustRevalidate} title="Account number (enter 5 to 12 characters, alphanumeric a-z, 0-9)" label="Account number" field="payment_account" placeholder="Account number (enter 5 to 12 characters, alphanumeric a-z, 0-9)" onChange={onChange} required={true} type="text" validateInput={validateAccountNumber} />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl mustRevalidate={mustRevalidate} title="Monthly withdrawal day (enter 1-28)" label="Withdrawal day" field="payment_day" placeholder="Monthly withdrawal day (enter 1-28)" onChange={onChange} required={true} type="number" minValue={1} maxValue={28} />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {layout &&
                            <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}
